import { useIsMobile } from "~/composables/useIsMobile";
import { useIsDesktop } from "~/composables/useIsDesktop";
import { useIsTablet } from "~/composables/useIsTablet";

export const useDevice = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};
